import { Exams } from "src/components/ui/exams";
import { AppliedPosts } from "./applied-posts";
import { DashboardLayout } from "../Dashboard/DashboardLayout";
import { DesktopExamCalendar } from "src/components/exam-calendar";
import { useFetchAllSlotDates } from "src/redux/api/hooks/useFetchAllSlotDates";
import { useState } from "react";
import { convertDateToIST } from "src/lib/utils";
import { SelectSlots } from "../SelectSlot/select-slots";
import { useFetchExams } from "src/redux/api/hooks/useFetchExams";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

type View = "home" | "select";

export const AppliedPostsView = () => {
  const [view, setView] = useState<View>("home");

  const navigate = useNavigate();

  const { dates } = useFetchAllSlotDates();
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const { scheduledExams } = useFetchExams();

  console.log(scheduledExams, "applied post");

  const slotDatesArray: string[] = [];
  dates.forEach((date) => {
    slotDatesArray.push(convertDateToIST(new Date(date).toUTCString()));
  });

  const handleSelectDay = (day: Date) => {
    if (
      day &&
      slotDatesArray.includes(convertDateToIST(new Date(day).toUTCString()))
    ) {
      setSelectedDay(day);
      setView("select");
    }
  };

  const scheduledExamsIdArray: number[] = [];
  scheduledExams.forEach((exam) => {
    scheduledExamsIdArray.push(exam.exam.applicationId);
  });

  const viewAdmitCard = (id: number) => {
    if (scheduledExamsIdArray.includes(id)) {
      navigate(`/admit-card/${id}`);
    } else {
      toast.error("Exam is not Scheduled.");
    }
  };

  const handleBack = () => {
    setView("home");
    setSelectedDay(new Date());
  };

  const renderView = () => {
    switch (view) {
      case "home":
        return (
          <DashboardLayout>
            <div className="flex flex-grow flex-col justify-around gap-4 overflow-y-auto px-2 py-3">
              <div className="flex flex-col gap-4 xl:flex-row xl:justify-between">
                <div>
                  <h2 className="mb-5 text-xl font-bold text-zinc-700 lg:text-2xl">
                    Applied posts:
                  </h2>
                  <AppliedPosts
                    viewAdmitCard={viewAdmitCard}
                    isScheduled={scheduledExamsIdArray}
                  />
                </div>
                <div>
                  <h2 className="mb-5 text-xl font-bold text-zinc-700 lg:text-2xl">
                    Dates:
                  </h2>
                  <div className="justify-center lg:flex">
                    <DesktopExamCalendar
                      onSelect={handleSelectDay}
                      selectedDay={selectedDay}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-center">
                  <h1 className="text-xl font-bold my-3 w-[70%] text-center text-red-500">To avoid missing the exam, You must click the "Start Exam" button 10 minutes earlier of the time showing here</h1>
                </div>
                <div className="mx-auto mt-[3rem] overflow-auto rounded-lg border p-3 shadow-lg lg:max-h-[280px] lg:min-h-[210px] lg:w-[600px] xl:w-[1000px]">
                  <Exams />
                </div>
              </div>
            </div>
          </DashboardLayout>
        );
      case "select":
        return (
          <div className="flex min-h-[90dvh] items-center justify-center">
            <SelectSlots selectedDay={selectedDay} handleBack={handleBack} />
          </div>
        );
      default:
        break;
    }
  };

  return <div>{renderView()}</div>;
};
